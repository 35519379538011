.second-modal-body {
  padding: 0px 25%;
}

.margin-botton-leads {
  margin-bottom: -2px;
}
.rdrDefinedRangesWrapper {
  display: none;
}
.second-modal-body {
  padding: 0px 5%;
}
