.redbox{background-color: #FF3C35; width: 35px; height: 35px; text-align: center; align-items: center;
    display: flex; justify-content: center; border-radius: 8px; margin-bottom: 25px;}
.greenbox{background-color: #73C285; width: 35px; height: 35px; text-align: center; align-items: center;
    display: flex; justify-content: center; border-radius: 8px; margin-bottom: 25px;}
.bluebox{background-color: #009AA6; width: 35px; height: 35px; text-align: center; align-items: center;
    display: flex; justify-content: center; border-radius: 8px; margin-bottom: 25px;}
.redbox img{width: 18px;}
/* .red {background-color: rgba(255, 0, 0, 0.1);} */
.dashboardcardnew{width: 100%; border-radius: 16px 16px 0px 0px; background-color: #fff; margin: 0px;
    padding: 30px 15px 15px 15px; float: left; display: flex; justify-content: space-between; box-shadow: 0px -7px 10px 0px #eee;
    margin-top: 30px;}
.maincontainer .container{padding:0px;}
.totalpending{width: 100%; padding: 10px 20px;}
.filter{width: 100%;
    float: left;
    padding: 15px;}
.allproductmaindivrightarrow{position: absolute; top: 33%; right: 4%;}