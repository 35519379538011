.side-nav {
  position: fixed;
  background-color: white;
  width: 350px;
  height: 100%;
  z-index: 100;
  display: inherit;
}
.upper-div {
  background-color: #2db2bc;
  /* height: 45%; */
  width: 100%;
  /* margin-bottom: 100%; */
}
.round-div {
  width: 58px;
  border-radius: 50%;
  background-color: white;
  height: 58px;
  text-align: center;
}

.round-div h1 {
  padding: 6px 20px;
  color: #2db2bc;
  font-size: 43px;
  font-weight: 700;
}
.profilecontainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  margin: 15px 0 15px 0;
}

li.sidebar-list.list-group-item {
  font-size: 14px;
  padding: 20px 30px;
}

.Leftprofilename {
  margin: 15px 0 0 0;
}
.Leftprofilename p {
  font-size: 14px;
  color: #fff;
  margin: 0;
}
.list-menu {
  position: absolute;
  width: 105%;
  /* margin-bottom: 125px; */
}
.back-img {
  height: 20px;
}
.leftheader {
  margin: 3px;
}
.name-box {
  margin-top: 20%;
  width: 90%;
}
.sidebar-list {
  border: none !important;
  color: black;
  /* border-bottom: 1px!important; */
}
.sidebar-hr {
  border: 0;
  margin: 0%;
  width: 95%;
  border-top: 1px solid #dedede;
}
.sidenav-icon {
  margin-right: 8px;
  float: left;
}
