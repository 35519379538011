#filterbox {
    border-radius: 50px;
    border: 1px solid #009aa6;
    padding: 3px 10px;
    font-size: 11px;
    color: #5a5a5a;
    float: right;
    margin-top: -3px;
    width: 100%;}
.personaldetail{background-color: #fff;}
.personaldetail .container{padding-left: 0px; padding-right: 0px;}


.personalContent{width: 100%; border-radius: 16px 16px 0px 0px; background-color: #fff; margin: 0px;
    padding: 100px 0px 0px 0px; box-shadow: 0px -4px 10px 0px #eee; padding: 15px; margin-top: 0px;
    margin-bottom: 0px;}
.personaldiv {width: 100%; float: left; padding: 10px;   margin-bottom: 5px;}
.personaldivleft {
    width: 12%;
    float: left;
    margin: 3px 0 0 0;
}
.personaldivleft h3{font-size: 16px; color: #009AA6;}
.personaldivleft h5{font-size: 12px;}
.personaldivleft p{font-size: 10px; color: #bababa; margin-bottom: 0px;}

.personaldivright {width: 85%; float: left; text-align: left;}
.personaldivright h3{color: #009AA6; font-weight: bold; margin-bottom: 0px; font-size: 20px;}
.personaldivright p{color: #000; font-size: 15px; margin-bottom: 0px;}
.personaldivright h5{color: #009AA6; font-size: 15px;}
.personaluserdiv{margin-bottom: 20px;}

.callscript{width: 100%; float: left;}
.callscript select{margin-bottom: 15px;}
.callscript h3{color: #009AA6; font-weight: bold; font-size: 24px;}
.callnowdiv{width: 100%; padding: 15px 20px; text-align: center; background-color: #009AA6; position:fixed;
bottom: 0px; margin: 0 auto;}
.callnowdiv p{color: #fff; font-size: 20px; margin-bottom: 0px;}
.callnowdiv p img{margin-right: 10px; width: 35px;}

.History h3{color: #009AA6; font-weight: bold; font-size: 24px;}

.callscriptfirstdiv{width: 100%; float: left; padding: 15px; padding-top: 0px;}
.callblowContent h2{font-size: 18px; margin-bottom: 5px;}
.picker-input__text{margin-bottom: 20px; height: 50px; font-size: 14px !important; background-color: #f8f8f8;}

.react-time-picker {width: 100%; border: 1px solid #eee; height: 50px;}
.react-time-picker__wrapper{border: thin solid #eee; background-color: #f8f8f8;}
.react-time-picker__clear-button__icon{display: none !important;}
#dispositionwrap select.form-control {
    font-size: 14px;
    height: 50px;
    background-color: #eee;
    margin-bottom: 20px;
}
.modal-content.mt30 {
    margin-top: 0px;
}
.react-time-picker__inputGroup{padding-left: 10px;}
.selctdiv{padding: 15px; padding-top: 0px;}
.otherdivContent{width: 100%; border-radius: 16px 16px 0px 0px; background-color: #fff; margin: 0px;
    padding: 100px 0px 0px 0px; box-shadow: 0px -4px 10px 0px #eee; padding: 15px; margin-top: 0px;
    margin-bottom: 0px;}
.personaldivleft input[type=radio]{
    width: 22px; 
    height: 22px;
    accent-color: #009AA6
}