#filterbox {
    border-radius: 50px;
    border: 1px solid #009aa6;
    padding: 3px 10px;
    font-size: 11px;
    color: #5a5a5a;
    float: right;
    margin-top: -3px;
    width: 100%;}
.pageone{background-color: #fff;}
.pageone .container{padding-left: 0px; padding-right: 0px;}

.newleaddiv{width: 100%; float: left; background-color: #fff; padding: 10px 15px; margin-top: 30px;}
.newleaddivleft {width: auto; float: left; text-align: left;}
.newleaddivleft h4{ color: #009AA6; font-size: 18px; font-weight: bold;}
.newleaddivleft h4 span{    padding: 6px 8px;
    background-color: #73C285;
    border-radius: 100%;
    color: #fff;
    margin-left: 10px;
    font-size: 12px;
    font-weight: normal;
    width: 25px;
    height: 25px;
    float: right;
    text-align: center;}
.newleaddivright{width: 30%; float: right;}

.pageoneContent{width: 100%; border-radius: 16px 16px 0px 0px; background-color: #fff; margin: 0px;
    padding: 100px 0px 0px 0px; box-shadow: 0px -4px 10px 0px #eee; padding: 15px; max-height: 500px; overflow: scroll;}
.pageonediv {width: 100%; float: left; background-color: #fff; padding: 10px; border-radius: 8px; 
    box-shadow: 0px 0px 10px 0px #eee; margin-bottom: 15px;}
.pageonedivleft {width: 70%; float: left;}
.pageonedivleft h3{font-size: 16px; color: #009AA6;}
.pageonedivleft h5{font-size: 12px;}
.pageonedivleft p{font-size: 10px; color: #bababa; margin-bottom: 0px;}

.pageonedivright {width: 30%; float: left; text-align: center;}
.pageonedivright h2{color: #f00; font-weight: bold; margin-bottom: 0px;}
.pageonedivright p{color: #f00; font-size: 14px; margin-bottom: 0px;}

.callnowdiv{width: 100%; padding: 15px 20px; text-align: center; background-color: #009AA6; position:fixed;
bottom: 0px; margin: 0 auto;}
/* .callnowdiv img{float: left;} */
.callnowdiv p{color: #fff; font-size: 20px; margin-bottom: 0px;}
.callnowdiv p img{margin-right: 10px; width:35px;}
.modal-dialog {margin-top: 50px;}
.modal-content{border-radius: 16px;}
.modal-body h2{font-size: 30px; font-weight: bold; color: #009AA6;}
.modal-header .close {top: -25px; background-color: #fff; color: #000;}
.modal{background-color: rgba(0, 0, 0, 0.8);}